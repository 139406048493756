import * as Constantes from '../../../shared/constants';

// módulos
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// servicios
import { EntradasService } from 'src/app/services/datos/entradas.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { ModalFactEntradaService } from 'src/app/services/modal-fact-entrada.service';

// interfaces
import { IEntrada } from 'src/app/interfaces/entrada';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';
import { ICliente } from 'src/app/interfaces/cliente';

// Librerías
import { ColumnMode } from '@swimlane/ngx-datatable';
import * as XLSX from 'xlsx';
import { delay, Subscription } from 'rxjs';



@Component({
  selector: 'app-entradas-ubicadas-lista',
  templateUrl: './entradas-ubicadas-lista.component.html',
  styleUrls: ['./entradas-ubicadas-lista.component.scss']
})
export class EntradasUbicadasListaComponent implements OnInit {

  //Usar constantes en el template
  Constantes = Constantes;

  @ViewChild('myTable') table: any;

  public enviado: boolean = false;

  public clientesList: ICliente[] = [];
  cliSeleccionado: string = '';

  fechaDesde: string = '';
  fechaHasta: string = '';

  // Errores
  public errores: any = {}

  // Control de cuando se cierra el modal
  modalClosedSubscription: Subscription | undefined;


  public loading: boolean = false
  opcionesList: IListaOpciones = {}

  public lista: IEntrada[] = [];
  public rows: any = [];
  public temp: any[] = [];

  public swEnStock: string = '1';  // 1=Pendiente de Ubicar fisicamente, 2= Está fisicamente en el almacén
  public subTitulo: string = '';

  expanded: any = {};
  timeout: any;

  ColumnMode = ColumnMode;
  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  textoDestacado: string = '';

  constructor(
    private usuarioSrv: UsuarioService,
    public modalFactEntradaSrv: ModalFactEntradaService,
    private clientesSrv: ClientesService,
    public entradaSrv: EntradasService,
    private notificacionesSrv: NotificacionesService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    // Suscribirse a los parámetros de la url
    this.activatedRoute.params.subscribe(params => {
      this.swEnStock = params['swEnStock']
      this.subTitulo = 'Pendientes de ubicar físicamente en el almacén'
      if (this.swEnStock === '2') this.subTitulo = 'TODOS los artículos Están fisicamente en el almacén'
      this.rows = {}
      this.cargaClientes()
    })


    this.modalClosedSubscription = this.modalFactEntradaSrv.modalClosed.subscribe(() => {
      // Suscribirse a modalClosed para saber cuando se cierra el modal y actualizar los datos de los pedidos
      console.log('__El modal se ha cerrado...:');
      this.getListaEntradas()
    });

    const currentDate = new Date();

    // Primer día del mes
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    this.fechaDesde = this.formatDate(firstDay);

    // Último día del mes
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    this.fechaHasta = this.formatDate(lastDay);
  }

  enviar() {
    this.enviado = true;

    // validar formulario
    this.errores = {}

    if (!this.cliSeleccionado) this.errores.cliSeleccionado = 'Requerido';
    if (!this.fechaDesde) this.errores.fechaDesde = 'Requerido';
    if (!this.fechaHasta) this.errores.fechaHasta = 'Requerido';

    if (this.fechaDesde && this.fechaHasta && new Date(this.fechaHasta) < new Date(this.fechaDesde)) {
      this.errores.fechaHasta = 'Debe ser mayor o igual a la fecha desde';
    }

    if (Object.keys(this.errores).length) {
      console.log('__enviar() ERRORES: ', Object.keys(this.errores).length);
      return
    }

    // Todo ok
    this.getListaEntradas()
  }



  // Cargar la lista de entradas con filtro
  getListaEntradas() {

    const datos: IListaOpciones = {
      'swCompleto': '1',
      'swEnStock': this.swEnStock,
      'id_cliente': parseInt(this.cliSeleccionado),
      'fechaDesde': this.fechaDesde,
      'fechaHasta': this.fechaHasta
    };

    console.log('opcionesList', datos)

    this.loading = true
    this.entradaSrv.getLista(datos)
      .subscribe(respuesta => {
        this.loading = false
        this.lista = respuesta.data;
        this.rows = this.lista;

        console.log('__getListaEntradas()', respuesta)
      })
  }


  cargaClientes() {
    this.loading = true
    this.clientesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.clientesList = respuesta.data;
      })
  }


  onClienteSelect(id: string) {
    this.cliSeleccionado = id;
  }


  /// AUX ////

  // Formatea una fecha como yyyy-mm-dd
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Número de errores en el formulario
  getErroresLength(): number {
    return Object.keys(this.errores).length;
  }



  onPage(event: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log('paged!', event);
    }, 100);
  }


  toggleExpandRow(row: any) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event: any) {
    console.log('Detail Toggled', event);
  }



  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }

  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );
    this.textoDestacado = txt;
    this.rows = temp;
    this.table = this.lista;
  }

  resaltarTexto(value: string, textoDestacado: string) {
    if (!textoDestacado) {
      return value;
    }
    return value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>');
  }

  exportToExcel(): void {
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'datos.xlsx');
  }



  creaPdf(data: any) {
    // monta un html con los datos de la ínea y llama al método para crear convertirlo en PDF
    console.log('__creaPdf()', data)

    // Estilos
    let htmlString: string = `
      <style> 
        table {
          border-collapse: collapse;  
          width: 100%;  
          font-family: Arial, sans-serif;  
        }
        th, td {
          border: 1px solid black;  
          padding: 8px;  
          text-align: left;  
        }
        th {
          background-color: #f2f2f2;  
        } 
      </style>
      `;

    // Cabecera 
    htmlString += `
      <h1>Entrada ubicada</h1>
      <table border="1">
        <tr>
          <th>Almacén</th>
          <th>Cliente</th>
          <th>Albarán</th>
          <th>Proveedor</th>
          <th>Fecha Entrada</th>
        </tr>
        <tr>
          <td>${data.almNombre}</td>
          <td>${data.cliNombre}</td>
          <td>${data.albaran}</td>
          <td>${data.proveedor}</td>
          <td>${data.created_at}</td>
        </tr>
      </table>
      <h2>Ubicaciones</h2>
      <table border="1">
        <tr>
          <th>Palé</th>
          <th>Descripción</th>
          <th>SKU</th>
          <th>Lote</th>
          <th>Cantidad</th>
          <th>Ubicación</th>
        </tr>
      `;


    // detalles  
    let paleAnterior = '';
    data.detall.forEach((detalle: any) => {

      // palé, linea en blanco si cambia
      if (detalle.pale !== paleAnterior) {
        if (paleAnterior !== '') {
          htmlString += `<tr><td colspan="6"> </td></tr>`;
        }
        paleAnterior = detalle.pale;
      }

      // lote
      let loteWk = '';
      if (detalle.lote) loteWk = detalle.lote


      htmlString += `
        <tr>
          <td style="text-align: right;">${detalle.pale}</td>
          <td>${detalle.artDescripcion}</td>
          <td style="white-space: nowrap;">${detalle.artSku}</td> 
          <td style="white-space: nowrap;">${loteWk}</td> 
          <td style="text-align: right;">${detalle.cantidad}</td>  
          <td>${detalle.ubicacion}</td>
        </tr>
      `;
    });

    // Cerrar la tabla y agregar el final del HTML
    htmlString += `</table>`;


    const datos = {
      'html': htmlString,
      'pdfName': 'entrada_' + data.id,
      'id_usuario': this.usuarioSrv.userdata.id

    }

    this.loading = true
    this.entradaSrv.creaPdf(datos)
      .subscribe(resp => {
        this.loading = false
        console.log(resp)
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          return
        }
        this.notificacionesSrv.aviso('success', resp.mensaje);
      })


  }



  modalFacturacion(row: any) {
    // Abre el modal para editar los datos de facturación

    console.log('__modalFacturacion()', row);

    this.modalFactEntradaSrv.entrada = row
    this.modalFactEntradaSrv.abrirModal();
  }

  ///// AUX  /////


  limitarYAgregarPuntos(value: string, maxLength: number): string {
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + '...';
    } else {
      return value;
    }
  }





  public sumaCeldas(cells: any[]) {
    // Devuelve la suma de la columna, formateada y sin decimales
    const filteredCells = cells.filter(cell => !!cell);
    const sum: number = filteredCells.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
    // return sum.toLocaleString('es');
    return sum.toLocaleString('de-DE');
  }




}
