<div class="rounded border">
    <h5 class="text-info p-1">Stock en carros</h5>

    <!-- ngx-datatable -->
    <ngx-datatable class='bootstrap'
                   [columnMode]="ColumnMode.flex"
                   [loadingIndicator]="loading"
                   [headerHeight]="70"
                   [footerHeight]="50"
                   [rowHeight]="50"
                   [limit]="rowsLimit"
                   [rows]='rows'
                   [messages]="messages"
                   [footerHeight]="50"
                   [summaryRow]="true"
                   [summaryHeight]="55"
                   [summaryPosition]="summaryPosition">


        <!-- id -->
        <ngx-datatable-column name="id"
                              [flexGrow]="10">
            <ng-template ngx-datatable-header-template
                         let-sort="sortFn"
                         let-column="column">
                <input type="search"
                       class="form-control form-control-sm"
                       placeholder="Buscar..."
                       (keyup)="buscar($event, 'id')"><br>
                <div class="header"
                     (click)="sort()">
                    <h6 class="pointer link">id</h6>
                </div>
            </ng-template>
            <ng-template let-value="value"
                         ngx-datatable-cell-template>
                <div (dblclick)="dblclickCampo(value)"
                     title="Doble click para busqueda global"
                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
            </ng-template>
        </ngx-datatable-column>



        <!-- recogida_cod -->
        <ngx-datatable-column name="Recogida"
                              prop='recogida_cod'
                              [flexGrow]="15">
            <ng-template ngx-datatable-header-template
                         let-sort="sortFn"
                         let-column="column">
                <input type="search"
                       class="form-control form-control-sm"
                       placeholder="Buscar..."
                       (keyup)="buscar($event, 'recogida_cod')"><br>
                <div class="header"
                     (click)="sort()">
                    <h6 class="pointer link">Recogida</h6>
                </div>
            </ng-template>
            <ng-template let-value="value"
                         let-row="row"
                         ngx-datatable-cell-template>
                <div (dblclick)="dblclickCampo(value)"
                     title="Doble click para busqueda global"
                     title="{{row.clienteNombre}}">
                    <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>
                </div>

            </ng-template>
        </ngx-datatable-column>



        <!-- id_articulo -->
        <ngx-datatable-column name="id Art."
                              prop='id_articulo'
                              [flexGrow]="10">
            <ng-template ngx-datatable-header-template
                         let-sort="sortFn"
                         let-column="column">
                <input type="search"
                       class="form-control form-control-sm"
                       placeholder="Buscar..."
                       (keyup)="buscar($event, 'id_articulo')"><br>
                <div class="header"
                     (click)="sort()">
                    <h6 class="pointer link">id Art.</h6>
                </div>
            </ng-template>
            <ng-template let-value="value"
                         let-row="row"
                         ngx-datatable-cell-template>
                <div (dblclick)="dblclickCampo(value)"
                     title="Doble click para busqueda global"
                     title="{{row.clienteNombre}}">
                    <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>
                </div>

            </ng-template>
        </ngx-datatable-column>



        <!-- sku -->
        <ngx-datatable-column name="sku"
                              [flexGrow]="20">
            <ng-template ngx-datatable-header-template
                         let-sort="sortFn"
                         let-column="column">
                <input type="search"
                       class="form-control form-control-sm"
                       placeholder="Buscar..."
                       (keyup)="buscar($event, 'sku')"><br>
                <div class="header"
                     (click)="sort()">
                    <h6 class="pointer link">SKU</h6>
                </div>
            </ng-template>
            <ng-template let-value="value"
                         let-row="row"
                         ngx-datatable-cell-template>
                <div (dblclick)="dblclickCampo(value)"
                     title="Doble click para busqueda global">
                    <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>
                    <span *ngIf="row.articuloSwDefectuoso == Constantes.DEFECTUOSO">
                        <i title="Defectuoso"
                           class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>
                    </span>
                </div>

            </ng-template>
        </ngx-datatable-column>



        <!-- ean -->
        <ngx-datatable-column name="ean"
                              [flexGrow]="20">
            <ng-template ngx-datatable-header-template
                         let-sort="sortFn"
                         let-column="column">
                <input type="search"
                       class="form-control form-control-sm"
                       placeholder="Buscar..."
                       (keyup)="buscar($event, 'ean')"><br>
                <div class="header"
                     (click)="sort()">
                    <h6 class="pointer  link">Ean</h6>
                </div>
            </ng-template>
            <ng-template let-value="value"
                         ngx-datatable-cell-template>
                <div (dblclick)="dblclickCampo(value)"
                     title="Doble click para busqueda global"
                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
            </ng-template>
        </ngx-datatable-column>





        <!-- descripcion -->
        <ngx-datatable-column name="descripcion"
                              [flexGrow]="35">
            <ng-template ngx-datatable-header-template
                         let-sort="sortFn"
                         let-column="column">
                <input type="search"
                       class="form-control form-control-sm"
                       placeholder="Buscar..."
                       (keyup)="buscar($event, 'descripcion')"><br>
                <div class="header"
                     (click)="sort()">
                    <h6 class="pointer  link">Descripcion</h6>
                </div>
            </ng-template>
            <ng-template let-value="value"
                         ngx-datatable-cell-template>
                <div (dblclick)="dblclickCampo(value)"
                     title="Doble click para busqueda global"
                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
            </ng-template>
        </ngx-datatable-column>





        <!-- estado PREPARACION -->
        <ngx-datatable-column name="estado"
                              [flexGrow]="15">
            <ng-template ngx-datatable-header-template
                         let-sort="sortFn"
                         let-column="column">
                <input type="search"
                       class="form-control form-control-sm"
                       placeholder="Buscar..."
                       (keyup)="buscar($event, 'estado')"><br>
                <div class="header"
                     (click)="sort()">
                    <h6 class="pointer link">Recogida</h6>
                </div>
            </ng-template>
            <ng-template let-value="value"
                         ngx-datatable-cell-template>
                <span [innerHTML]="value | carritoEstado"></span>
            </ng-template>
        </ngx-datatable-column>


        <!-- estado REPARTO -->
        <ngx-datatable-column name="estadoReparto"
                              [flexGrow]="15">
            <ng-template ngx-datatable-header-template
                         let-sort="sortFn"
                         let-column="column">
                <input type="search"
                       class="form-control form-control-sm"
                       placeholder="Buscar..."
                       (keyup)="buscar($event, 'estado')"><br>
                <div class="header"
                     (click)="sort()">
                    <h6 class="pointer link">Reparto</h6>
                </div>
            </ng-template>
            <ng-template let-value="value"
                         ngx-datatable-cell-template>
                <span [innerHTML]="value | repartoEstado"></span>
            </ng-template>
        </ngx-datatable-column>




        <!-- uic -->
        <ngx-datatable-column name="uic"
                              pre="uic"
                              [flexGrow]="18">
            <ng-template ngx-datatable-header-template
                         let-sort="sortFn"
                         let-column="column">
                <input type="search"
                       class="form-control form-control-sm"
                       placeholder="Buscar..."
                       (keyup)="buscar($event, 'ubiCodigo')"><br>
                <div class="header"
                     (click)="sort()">
                    <h6 class="pointer link">UIC</h6>
                </div>
            </ng-template>
            <ng-template let-value="value"
                         let-row="row"
                         ngx-datatable-cell-template>
                <div (dblclick)="dblclickCampo(value)"
                     title="Doble click para busqueda global"
                     class="monospace"
                     [innerHTML]="resaltarTexto(value, textoDestacado )"></div>
            </ng-template>
        </ngx-datatable-column>



        <!-- estado_uic -->
        <ngx-datatable-column name="uic"
                              prop='uicData.estado'
                              [flexGrow]="15"
                              [resizeable]="false"
                              [sortable]="false">
            <ng-template ngx-datatable-header-template>
                <div>
                    <i class="fas"
                       [ngClass]="'fa-solid fa-dolly'"></i>
                </div>
            </ng-template>
            <ng-template let-value="value"
                         let-row="row"
                         ngx-datatable-cell-template>
                <div *ngIf="row.uic"
                     [innerHTML]="value | uicLabelEstado"></div>
            </ng-template>
        </ngx-datatable-column>
        


        <!-- cant_recogida -->
        <ngx-datatable-column name="Cantidad"
                              prop='cant_recogida'
                              [flexGrow]="10"
                              [cellClass]="'text-right'"
                              [summaryFunc]="sumaCeldas">
            <ng-template ngx-datatable-header-template
                         let-sort="sortFn"
                         let-column="column">
                <input type="search"
                       class="form-control form-control-sm"
                       placeholder="Buscar..."
                       (keyup)="buscar($event, 'cant_recogida')"><br>
                <div class="header"
                     (click)="sort()">
                    <h6 class="pointer link">Cantidad</h6>
                </div>
            </ng-template>
            <ng-template let-value="value"
                         let-row="row"
                         ngx-datatable-cell-template>
                <div (dblclick)="dblclickCampo(value)"
                     title="Doble click para busqueda global"
                     title="Cantidad recogida en este carrito">
                    <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>
                </div>

            </ng-template>
        </ngx-datatable-column>





        <!-- updated_at -->
        <ngx-datatable-column name="updated_at"
                              prop='updated_at'
                              [flexGrow]="20">
            <ng-template ngx-datatable-header-template
                         let-sort="sortFn"
                         let-column="column">
                <input type="search"
                       class="form-control form-control-sm"
                       placeholder="Buscar..."
                       (keyup)="buscar($event, 'updated_at')"><br>
                <div class="header"
                     (click)="sort()">
                    <h6 class="pointer link">Fecha hora</h6>
                </div>
            </ng-template>
            <ng-template let-value="value"
                         ngx-datatable-cell-template>
                <div (dblclick)="dblclickCampo(value)"
                     title="Doble click para busqueda global"
                     style="font-family: 'Verdana', monospace; letter-spacing: 0.2px;"
                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
            </ng-template>
        </ngx-datatable-column>







    </ngx-datatable>

</div>