<div class="row animated fadeIn fast">


    <!-- Formulario de ubicación desde ubi -> hasta Ubi -->
    <div class="col-md-12 col-xs-12 animated fadeIn fast">

        <form class="form p-t-20"
              [formGroup]="ubiForm"
              (ngSubmit)="enviar()"
              class="form-horizontal ">

            <div class="card border border-success rounded">

                <div class="card-body">
                    <h4 class="card-title text-info">Ubicaciones</h4>
                    <h6 class="card-subtitle"> </h6>
                    <div class="row">

                        <!-- ubiOrigen -->
                        <div class="col-md-5">
                            <div class="form-group">
                                <label class="form-control-label">Ubicación origen: {{clienteOrigen}} </label>
                                <input type="
                                          text"
                                       class="form-control grande"
                                       style="font-family: 'Verdana', monospace; letter-spacing: 0.6px;"
                                       value="{{ubiOrigen | mask:'00.00.00.00.00.00'}}"
                                       readonly>
                                <small>{{codUbiOriDecript}}</small>
                            </div>
                        </div>

                        <div class="col-md-2 d-flex justify-content-center">
                            <i class="mt-5 fa-solid fa-arrow-right-long fa-2xl"></i>
                        </div>

                        <!-- ubiDestino -->
                        <div class="col-md-5">
                            <div class="form-group"
                                 [ngClass]="{'has-danger': enviado && f['ubiDestino'].errors }">
                                <label class="form-control-label">Nueva ubicación: {{clienteDestino}} </label>
                                <input type="text"
                                       formControlName="ubiDestino"
                                       (input)="cambiaUbiDes()"
                                       style="font-family: 'Verdana', monospace; letter-spacing: 0.6px;"
                                       mask="00.00.00.00.00.00"
                                       class="form-control grande"
                                       [ngClass]="{ 'form-control-danger': enviado && f['ubiDestino'].errors }">

                                <div *ngIf="enviado && f['ubiDestino'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['ubiDestino'].errors['required']">Obligatorio</div>
                                    <div *ngIf="f['ubiDestino'].errors['minlength']">Deben ser 12 digitos</div>
                                    <div *ngIf="f['ubiDestino'].errors['maxlength']">Deben ser 12 digitos</div>
                                    <div *ngIf="f['ubiDestino'].errors['clienteNotMatch']">Ubicación ocupada por otro cliente</div>
                                    <div *ngIf="f['ubiDestino'].errors?.['apiError']">{{ubiForm.get('ubiDestino')?.errors?.['apiError']}}</div>
                                </div>
                                <small>{{codUbiDesDecript}}</small>
                            </div>
                        </div>


                        <!-- facturable -->
                        <div class="col-md-2">

                            <div class="form-group"
                                 [ngClass]="{'has-danger': enviado && f['facturable'].errors }">

                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox"
                                           class="custom-control-input"
                                           id="facturableCheckbox"
                                           formControlName="facturable">
                                    <label class="custom-control-label"
                                           for="facturableCheckbox">Facturable</label>
                                </div>

                                <div *ngIf="enviado && f['facturable'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['facturable'].errors?.['apiError']">{{ubiForm.get('facturable')?.errors?.['apiError']}}</div>
                                </div>

                            </div>
                        </div>

                    </div> <!-- row -->

                </div>


                <div class="card-footer">

                    <div class="d-flex">
                        <button [disabled]="loading"
                                type="button"
                                class="btn waves-effect waves-light btn-rounded btn-secondary"
                                [routerLink]="'/panel/stock_lista'">Cancelar</button>

                        <button [disabled]="loading"
                                type="submit"
                                class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                            <i *ngIf="loading"
                               class="fa fa-spinner fa-spin"></i>
                            Enviar</button>
                    </div>

                </div><!-- card footer -->

            </div>
        </form>

    </div>





    <!-- Lista del Stock en ubiOrigen -->
    <div *ngIf="stockList.length"
         class="col-md-12 col-xs-12 animated fadeIn fast">

        <div class="card border border-info rounded">
            <div class="card-body">
                <h4 class="card-title text-info">Artículos a mover</h4>
                <h6 class="card-subtitle">Stock actual en ubicación <b>ORIGEN</b> </h6>
                <div class="bootstrap-table">


                    <div class="table-responsive">
                        <table class="table table-sm table-striped table-bordered table-hover">

                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Id Art.</th>
                                    <th>Cliente</th>
                                    <th>SKU</th>
                                    <th>Ean</th>
                                    <th>Descripción</th>
                                    <th>Cantidad</th>
                                    <th>Reservado</th>
                                    <th class="text-success font-weight-bold">Disponible</th>
                                    <th>Lote</th>
                                    <th>F.Entrada</th>
                                </tr>
                            </thead>

                            <!-- Si hay detall y no está ubicado, mostrarlo -->
                            <tbody>
                                <ng-container *ngFor="let lin of stockList; let i = index">
                                    <tr>
                                        <td>{{ lin.id }}</td>
                                        <td>{{ lin.id_articulo }}</td>
                                        <td>{{ lin.clienteNombre }}</td>
                                        <td>
                                            {{ lin.articuloSku }}
                                            <span *ngIf="lin.articuloSwDefectuoso == Constantes.DEFECTUOSO">
                                                <i title="Defectuoso"
                                                   class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>
                                            </span>
                                        </td>
                                        <td>{{ lin.articuloEan }}</td>
                                        <td>{{ lin.articuloDescripcion }}</td>
                                        <td style="text-align: right; padding-left: 10px">
                                            <div class="pl-25 mr-20 monospace">
                                                {{lin.cantidad | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td style="text-align: right; padding-left: 10px">
                                            <div class="pl-25 mr-20 monospace">
                                                {{lin.cantidadReservada | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td class="text-success"
                                            style="text-align: right; padding-left: 10px">
                                            <div class="pl-25 mr-20 monospace">
                                                {{lin.cantidadDisponible | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td>{{ lin.lote }}</td>
                                        <td>{{ lin.fechaEntrada }}</td>
                                    </tr>


                                </ng-container>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <th colspan="6"
                                        class="text-right text-success font-weight-bold">Artículos a mover
                                    </th>
                                    <th class="text-right"
                                        style="text-align: right; padding-left: 10px">
                                        <div class="pl-25 mr-20 monospace">
                                            {{sumas.cantidad | number: '0.0-0':'es'}}
                                        </div>
                                    </th>
                                    <th class="text-right"
                                        style="text-align: right; padding-left: 10px">
                                        <div class="pl-25 mr-20 monospace">
                                            {{sumas.reservado | number: '0.0-0':'es'}}
                                        </div>
                                    </th>
                                    <th class="text-right text-success"
                                        style="text-align: right; padding-left: 10px">
                                        <div class="font-weight-bold pl-25 mr-20 monospace">
                                            {{sumas.disponible | number: '0.0-0':'es'}}
                                        </div>
                                    </th>
                                    <th></th>
                                </tr>
                            </tfoot>

                        </table>
                    </div>



                </div>
            </div>

            <div class="clearfix"></div>
        </div>

    </div>






    <!-- Lista del Stock en ubiDestino -->
    <div *ngIf="ubiDestino.length === 12"
         class="col-md-12 col-xs-12 animated fadeIn fast">

        <div class="card border border-info rounded">
            <div class="card-body">
                <h6 class="card-subtitle">Stock actual en ubicación <b>DESTINO</b></h6>
                <div class="bootstrap-table">


                    <div class="table-responsive">
                        <table class="table table-sm table-striped table-bordered table-hover">

                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Id Art.</th>
                                    <th>Cliente</th>
                                    <th>SKU</th>
                                    <th>Ean</th>
                                    <th>Descripción</th>
                                    <th>Cantidad</th>
                                    <th>Reservado</th>
                                    <th>Disponible</th>
                                    <th>Lote</th>
                                    <th>F.Entrada</th>
                                </tr>
                            </thead>

                            <!-- Si hay detall y no está ubicado, mostrarlo -->
                            <tbody>
                                <ng-container *ngFor="let lin of stockDestinoList; let i = index">
                                    <tr>
                                        <td>{{ lin.id }}</td>
                                        <td>{{ lin.id_articulo }}</td>
                                        <td>{{ lin.clienteNombre }}</td>
                                        <td>
                                            {{ lin.articuloSku }}
                                            <span *ngIf="lin.articuloSwDefectuoso == Constantes.DEFECTUOSO">
                                                <i title="Defectuoso"
                                                   class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>
                                            </span>
                                        </td>
                                        <td>{{ lin.articuloEan }}</td>
                                        <td>{{ lin.articuloDescripcion }}</td>
                                        <td style="text-align: right; padding-left: 10px">
                                            <div class="pl-25 mr-20 monospace">
                                                {{lin.cantidad | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td style="text-align: right; padding-left: 10px">
                                            <div class="pl-25 mr-20 monospace">
                                                {{lin.cantidadReservada | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td style="text-align: right; padding-left: 10px">
                                            <div class="pl-25 mr-20 monospace">
                                                {{lin.cantidadDisponible | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td>{{ lin.lote }}</td>
                                        <td>{{ lin.fechaEntrada }}</td>
                                    </tr>
                                </ng-container>

                                <!-- no hay nada en destino, está vacia ó hay un error -->
                                <div *ngIf="!stockDestinoList.length"
                                     class="text-center">
                                    <h2 *ngIf="!stockDestinoListErr"
                                        class="text-success">Ubicación Vacia</h2>
                                    <h2 *ngIf="stockDestinoListErr"
                                        class="text-danger">{{stockDestinoListErr}}</h2>

                                </div>

                            </tbody>

                        </table>
                    </div>



                </div>
            </div>

            <div class="clearfix"></div>
        </div>

    </div>









</div>