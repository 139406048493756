<div *ngIf="loading"
     class="loading-overlay">
    <div class="spinner"></div>
    <h3 class="mt-3 ml-1 text-info ">Cargando...</h3>
</div>

<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <!-- cabecera para sustitución de artículo desde campanyaNew.component -->
            <div class="card-body">
                <div class="alert alert-success alert-dismissible fade show"
                     *ngIf="articuloExterno.id"
                     style="border-width: 2px! important;">
                    <h4><strong>Selecciona el artículo a Sustituir por el que aparece en el pedido {{articuloExterno.pedido_cli}} de {{parametrosUrl.cliNombre}}:</strong></h4>
                    <p class="mb-0">
                        <strong>EAN:</strong> {{articuloExterno.ean}},
                        <strong>SKU:</strong> {{articuloExterno.sku}},
                        <strong>Descripción:</strong> {{articuloExterno.descripcion}}
                    </p>
                    <div class="text-right">
                        <button [disabled]="loading"
                                type="button"
                                [routerLink]="'/panel/campanya_new'"
                                class="btn waves-effect waves-light btn-rounded btn-secondary">Cancelar cambio</button>
                    </div>
                </div>
            </div>

            <div class="card-body">

                <!-- cabecera -->
                <div class="row mb-2">

                    <div class="col-6">
                        <div class="input-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   [(ngModel)]="strBuscarTodo"
                                   (keyup)="buscarTodo($event)" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary"
                                        type="button"
                                        title="Limpiar búsqueda"
                                        (click)="limpiaBusca()">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="input-group border border-dark rounded">
                            <div class="input-group-addon"><i class="fa-solid fa-user"></i></div>
                            <select class="form-control"
                                    [disabled]="parametrosUrl.id_cliente"
                                    [(ngModel)]="cliSeleccionado"
                                    (ngModelChange)="onClienteSelect($event)">
                                <option value=''>Todos los clientes</option>
                                <option *ngFor="let cli of clientesList"
                                        [value]=cli.id>
                                    {{cli.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-3">
                        <form>

                            <div class="row float-right">
                                <div class="col-sm-6">
                                    <div class="form-group rounded">
                                        <select class="form-control"
                                                name="rowsLimitStr"
                                                [(ngModel)]="rowsLimitStr"
                                                (change)="rowsLimitChange()">
                                            <option [value]="10">10</option>
                                            <option [value]="50">50</option>
                                            <option [value]="100">100</option>
                                            <option [value]="999999">Todo</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group float-right">
                                        <div class="btn-group">
                                            <button type="button"
                                                    [routerLink]="'/panel/articulo_edit/new'"
                                                    class="btn btn-outline-info btn-circle"
                                                    title="Nuevo"
                                                    [disabled]="parametrosUrl.articuloExterno_id">
                                                <i class="fa-solid fa-plus"></i>
                                            </button>
                                            <button type="button"
                                                    (click)="exportToExcel()"
                                                    class="btn btn-outline-info btn-circle"
                                                    title="Descargar Excel">
                                                <i class="fa-solid fa-download"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </form>
                    </div>

                </div>



                <div class="rounded border">
                    <!-- datatable -->
                    <ngx-datatable class='bootstrap'
                                   [style.width]="'100%'"
                                   [columns]="columns"
                                   [columnMode]="'force'"
                                   [loadingIndicator]="loading"
                                   [headerHeight]="70"
                                   [footerHeight]="50"
                                   [rowHeight]="50"
                                   [limit]="rowsLimit"
                                   [rows]='rows'
                                   [messages]="messages">





                        <!-- id -->
                        <ngx-datatable-column name="id"
                                              [width]="1"
                                              [maxWidth]="50"
                                              [resizeable]="false">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <div class="header noSearch"
                                     (click)="sort()">
                                    <h6 class="title link">id</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>




                        <!-- foto -->
                        <ngx-datatable-column name=''
                                              prop="foto"
                                              [width]="1"
                                              [maxWidth]="50"
                                              [resizeable]="false"
                                              [sortable]="false">

                            <ng-template ngx-datatable-cell-template
                                         let-row="row"
                                         let-value="value">

                                <div *ngIf="value">
                                    <img [src]="fotoDir +'/articulos' + imgResized + value"
                                         loading="lazy"
                                         (click)="abrirModal(row)"
                                         alt="Foto"
                                         class="rounded pointer border"
                                         height="25px"
                                         title="Cambiar/borrar foto">
                                </div>

                            </ng-template>
                        </ngx-datatable-column>





                        <!-- cliNombre -->
                        <ngx-datatable-column name="cliNombre"
                                              [width]="5">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'cliNombre')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="title link">Cliente</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- sku -->
                        <ngx-datatable-column name="sku"
                                              [width]="5">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'sku')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="title link">SKU</h6>
                                </div>
                            </ng-template>

                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global">
                                    <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>
                                    <span *ngIf="row.swDefectuoso == Constantes.DEFECTUOSO"
                                          class="text-warning ml-12">
                                        <i title="Defectuoso"
                                           class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>
                                    </span>
                                </div>

                            </ng-template>
                        </ngx-datatable-column>



                        <!-- ean -->
                        <ngx-datatable-column name="ean"
                                              [maxWidth]="180">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'ean')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="title link">Ean</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- descripcion -->
                        <ngx-datatable-column name="descripcion"
                                              [maxWidth]="600">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'descripcion')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="title link">Descripción</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>

                                <span *ngIf="row.url">
                                    <a href="{{row.url}}"
                                       target="_blank"
                                       title="Abrir {{row.url}}">
                                        <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                                    </a>
                                </span>

                                <div *ngIf="!row.url"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>

                            </ng-template>

                        </ngx-datatable-column>




                        <!-- stock -->
                        <ngx-datatable-column name="Stock"
                                              prop='artStock'
                                              [cellClass]="'text-right'"
                                              [width]="50"
                                              [maxWidth]="100">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="title link mt-12"
                                        title="Volumen en m3">Stock</h6>
                                </div>
                            </ng-template>

                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>





                        <!-- volumen -->
                        <ngx-datatable-column name="Vol."
                                              prop='volumen'
                                              [width]="50"
                                              [maxWidth]="100">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="title link mt-12"
                                        title="Volumen en m3">Vol.</h6>
                                </div>
                            </ng-template>

                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div *ngIf="value > 0"
                                     [innerHTML]="resaltarTexto(value | number: '0.3-3':'es', textoDestacado)"></div>
                            </ng-template>

                        </ngx-datatable-column>




                        <!-- swLote -->
                        <ngx-datatable-column name="Lotes"
                                              prop='swLote'
                                              [width]="50"
                                              [maxWidth]="55">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="title link mt-12"
                                        title="Gestión de lotes">Lot.</h6>
                                </div>
                            </ng-template>

                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="value | iconCheck"></div>
                            </ng-template>

                        </ngx-datatable-column>



                        <!-- fecha -->
                        <!-- <ngx-datatable-column name="fecha"
                                              [maxWidth]="100">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'fecha')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="title link">F.entrada</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column> -->




                        <!-- Acciones -->
                        <ngx-datatable-column [width]="50"
                                              [maxWidth]="100"
                                              [sortable]="false"
                                              [resizeable]="false">


                            <ng-template let-row="row"
                                         let-value="value"
                                         ngx-datatable-cell-template>


                                <span *ngIf="!articuloExterno.id">
                                    <a [routerLink]="['/panel/articulo_edit',row.id]"
                                       class="link mr-2 pointer"
                                       title="Editar artículo">
                                        <span class="badge">E</span>
                                    </a>
                                </span>

                                <span *ngIf="articuloExterno.id">
                                    <a (click)="cambiaArticulo(row)"
                                       class="link mr-2 pointer"
                                       title="Seleccionar artículo">
                                        <span class="badge">S</span>
                                    </a>
                                </span>

                                <!-- <a [routerLink]="['/panel/articulo_edit',row.id]"
                                   class="link mr-2"
                                   title="Editar">
                                    <i class="fa-regular fa-pen-to-square fa"></i>
                                </a> -->


                                <!-- 
                                <a (click)="deleteRow(row)"
                                   class="link mr-2 pointer"
                                   title="Borrar">
                                    <i class="fa-regular fa-trash-can fa"></i>
                                </a> -->

                            </ng-template>


                        </ngx-datatable-column>

                    </ngx-datatable>
                </div>

            </div>
        </div>
    </div>

</div>