// Módulos
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

// Interfaces y Modelos  
import { IArtPreparacion } from 'src/app/interfaces/artPreparacion';
import { ICliente } from 'src/app/interfaces/cliente';
import { ICampanya } from 'src/app/interfaces/campanya';

// Servicios 
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { PreparacionesService } from 'src/app/services/datos/preparaciones.service';

// Librerías
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { IApiResp } from 'src/app/interfaces/api-resp';

@Component({
  selector: 'app-preparacion-new',
  templateUrl: './preparacion-new.component.html',
  styleUrls: ['./preparacion-new.component.scss']
})
export class PreparacionNewComponent implements OnInit, OnDestroy {

  id_campanya: string = '';

  public campForm: FormGroup = new FormGroup({});
  public enviado: boolean = false;
  public loading: boolean = false;

  // Intervalo de refresco de la tabla
  refreshInterval: any;
  timeRefreshCampanyas: number = environment.timeRefreshCampanyas;

  public fotoDir: string = environment.fotoDir;

  public lista: IArtPreparacion[] = [];
  public userList: any[] = [];
  public campanyaInfo: ICampanya = {};
  public clienteInfo: ICliente = {};

  selected: any = [];
  public totSelCantidad: number = 0;
  public totSelUbicaciones: number = 0;

  SelectionType = SelectionType;
  selectAllRows: boolean = false;
  selectNoAssigned: boolean = false;

  public rows: any = [];
  public temp: any[] = [];

  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';
  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  alturasList: string[] = [];
  public altura: string = '*';

  pasillosList: string[] = [];
  public pasillo: string = '*';




  textoDestacado: string = '';

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: `total`,
    selectedMessage: `seleccionados`
  }

  ColumnMode = ColumnMode;
  @ViewChild(PreparacionNewComponent) table: PreparacionNewComponent | any;

  constructor(
    private router: Router,
    private fb: FormBuilder,

    private prepSrv: PreparacionesService,
    private usuarioSrv: UsuarioService,
    private prepracionSrv: PreparacionesService,
    private notificacionesSrv: NotificacionesService,
    private activatedRoute: ActivatedRoute,

  ) {
    // Definir formulario 
    this.campForm = this.fb.group({
      id_usuario: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    // Suscribirse a los parámetros de la url, si envían una id, cargar los artículos de la campanya
    this.activatedRoute.params.subscribe(({ id }) => {
      this.id_campanya = id;
      // this.cargaArticulosList();
      this.cargaPreparacionesList();
    })

    // Configurar un intervalo para refrescar datos auomáticamente, solo se lanzará automáticamante si no se ha seleccionado ninguna preparación
    this.refreshInterval = setInterval(() => {
      this.refreshDataAuto();
    }, this.timeRefreshCampanyas); // tiempo para el Refesco automático de los datos de campañas

  }

  ngOnDestroy(): void {
    // Limpiar el intervalo cuando el componente se destruya
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }


  cargaPreparacionesList() {
    this.loading = true;
    this.lista = [];
    this.prepSrv.getPreparacionPorCampanya(Number(this.id_campanya))
      .subscribe(resp => {
        this.loading = false;

        console.log('__cargaPreparacionesList', resp);

        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          if (resp.mensaje == 'Campaña no encontrada') this.router.navigate(['/panel/campanyas_lista'])
        } else {
          this.lista = resp.data;
          this.campanyaInfo = resp.totales.campanya;
          this.clienteInfo = resp.totales.cliente;
        }


        if (resp.totales && resp.totales.existeAnterior) {
          this.notificacionesSrv.aviso('warning', 'ATENCIÓN, Para este cliente existen campañas anteriores a esta en estado pendiente ó parcialmente asignadas');
        }

        this.rows = this.lista;
        this.temp = [...this.lista];

        this.cargaAlturasList();
        this.cargaPasillosList();
        this.cargaUsuariosList();
      })

  }


  // Refrescar los datos de la tabla
  refreshData() {
    this.cargaPreparacionesList()
    this.cancelarSelecctedClick() // Desmarcar checks de seleccion
  }

  // Refrescar los datos de la tabla automáticamente solo se ejecutará si NO se ha seleccionado ninguna preparación
  refreshDataAuto() {
    if (!this.selected.length) {
      this.refreshData()
    }
  }

  cargaUsuariosList() {
    this.userList = []
    const params = {
      id_almacen: this.clienteInfo.alm_por_defecto,
      rol: 'usuario'
    }

    this.usuarioSrv.getUsrListRol(params)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
        } else {
          this.userList = resp.data;
        }

        console.log('__cargaUsuariosList', resp);
      })
  }



  cargaPasillosList() {
    this.lista.forEach(item => {
      const ubicacion = item.ubicacion;
      if (ubicacion && ubicacion.length >= 8) {
        const ubiPas = ubicacion.substr(2, 2);

        if (!this.pasillosList.includes(ubiPas)) {
          this.pasillosList.push(ubiPas);
        }
      }
    });
    this.pasillosList.sort();
  }

  cargaAlturasList() {
    this.lista.forEach(item => {
      const ubicacion = item.ubicacion;
      if (ubicacion && ubicacion.length >= 8) {
        const ubiAlt = ubicacion.substr(6, 2);

        if (!this.alturasList.includes(ubiAlt)) {
          this.alturasList.push(ubiAlt);
        }
      }
    });
    this.alturasList.sort();
  }


  enviar() {
    this.enviado = true;
    console.log(this.campForm);

    if (this.campForm.invalid) {
      console.log("ERRORES:", this.campForm);
      return;
    }

    // validar si existen lotes diferentes para un mismo artículo
    if (!this.validarLotes()) {
      this.notificacionesSrv.aviso('error', 'Has seleccionado ubicaciones con el mismo artículo y diferente lote')
      return;
    }

    // Todo ok, enviar al BackEnd 
    const datos = {
      ...this.campForm.value,
      selected: this.selected,
      fecha_entrada: new Date(),
      campanyaInfo: this.campanyaInfo
    }

    this.loading = true;
    console.log("_datos=", datos);


    this.prepSrv.asignaUsr(datos)
      .subscribe((resp: IApiResp) => {
        this.loading = false;

        console.log(resp);

        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          // for (let controlName in resp.mensaje) {
          //   this.campForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          // }
        } else {
          this.notificacionesSrv.aviso('success', resp.mensaje);
          // this.router.navigateByUrl(`panel/campanyas_lista`);
          this.selected = [];
          this.cargaPreparacionesList();
          return
        }
      })
  }


  cancelarSelecctedClick() {
    this.selectAllRows = false;
    this.selectNoAssigned = false;
    this.selected = [];
  }


  get f() {
    return this.campForm.controls;
  }

  buscaStock(row: any) {
    console.log('__buscaStock ', row)
    const preparacion: any = row;

    // Llamar al servicio para buscar nueva ubicación con el motivo
    preparacion.saltar = 'Sin Stock';
    this.loading = true;
    this.prepracionSrv.buscaUbiAlternativa(preparacion)
      .subscribe(async resp => {
        this.loading = false;
        console.log('__buscaUbicacionAlternativa()', resp);

        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          return;
        }
        if (resp.totales && resp.totales.salidaTipo === 'B') {
          this.notificacionesSrv.aviso('warning', resp.mensaje);

          await this.deletePreparacion(preparacion.id);

          // this.loading = true;
          // this.prepracionSrv.delete(preparacion.id)
          //   .subscribe(async resp => {
          //     this.loading = false;
          //     console.log(`__ this.prepracionSrv.delete(${preparacion.id})`, resp);
          //   });

        }
        if (resp.totales && resp.totales.salidaTipo === 'C') {
          this.notificacionesSrv.aviso('success', resp.mensaje);

          await this.deletePreparacion(preparacion.id);

          // this.loading = true;
          // this.prepracionSrv.delete(preparacion.id)
          //   .subscribe(async resp => {
          //     this.loading = false;
          //     console.log(`__ this.prepracionSrv.delete(${preparacion.id})`, resp);
          //   });

        }

        // Cargar las nuevas preparaciones
        this.cargaPreparacionesList();

      });

  }

  async deletePreparacion(id: number): Promise<void> {
    this.loading = true;
    try {
      const resp = await this.prepracionSrv.delete(id).toPromise();
      console.log(`__ this.prepracionSrv.delete(${id})`, resp);
    } catch (error) {
      console.error('__Error al eliminar preparación', error);
    } finally {
      this.loading = false;
    }
  }




  // Validar que no existan lotes diferentes para un mismo artículo en el array sleccionado
  validarLotes(): boolean {
    const articlesMap = new Map<string, Set<string>>();

    for (const detail of this.selected!) {
      const articleId = String(detail.id_articulo);
      const lote = String(detail.lote);

      if (lote) {

        if (!articlesMap.has(articleId)) {
          articlesMap.set(articleId, new Set());
        }

        const lotesSet = articlesMap.get(articleId);
        if (lotesSet) {
          lotesSet.add(lote);
          if (lotesSet.size > 1) {
            return false; // Encontrado el mismo artículo con diferentes lotes
          }
        }

      }

    }

    return true; // Todos los artículos tienen lotes únicos
  }



  //  **  AUX  ** 

  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }

  filtroUbiChange() {
    // Filtrar por pasillo y altura
    const temp = this.temp.filter(item => {
      const ubicacion = item.ubicacion;
      const ubiPas = ubicacion.substr(2, 2);
      const ubiAlt = ubicacion.substr(6, 2);

      const pasilloFiltro = this.pasillo === '*' || ubiPas === this.pasillo;
      const alturaFiltro = this.altura === '*' || ubiAlt === this.altura;

      return pasilloFiltro && alturaFiltro;
    });

    this.rows = temp;
    this.table = this.lista;
  }




  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );
    this.textoDestacado = txt;
    this.rows = temp;
    this.table = this.lista;
  }

  resaltarTexto(value: string | null, textoDestacado: string) {
    return value ? value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>') : '';
  }

  exportToExcel(): void {
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'datos.xlsx');
  }



  public sumaCeldas(cells: any[]) {
    // Devuelve la suma de la columna, formateada y sin decimales
    const filteredCells = cells.filter(cell => !!cell);
    const sum: number = filteredCells.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
    // return sum.toLocaleString('es');
    return sum.toLocaleString('de-DE');
  }

  public cuentaCeldas(cells: any[]) {
    // Devuelve el número de filas
    const filteredCells = cells.filter(cell => !!cell);
    const sum: number = filteredCells.reduce((accumulator, currentValue) => accumulator + 1, 0);
    return sum.toLocaleString('de-DE');
  }



  // ** Selección de líneas  ** //

  onActivate(event: any) {

    // Evitar selección en caso de doble click
    if (event.type === 'dblclick') {
      event.rowElement.classList.remove('datatable-row-selected'); // Desmarca la fila

      this.filtraEmpezados()
      this.totalesSelected();
      return;
    }


    if (event.type === 'click') {
      console.log('Activate Evento click', event);
      this.selectAllRows = false;

      this.filtraEmpezados()
      this.totalesSelected();

      console.log('_selected ', this.selected);
    }
  }


  toggleSelectAllRows() {
    // Establecer todos los elementos como seleccionados?
    this.selectNoAssigned = false;
    const allRows = [...this.rows];

    if (this.selectAllRows) this.selected = [...allRows];
    if (!this.selectAllRows) this.selected = [];

    this.filtraEmpezados();
    this.totalesSelected();
  }



  toggleNoAssigned() {

    console.log('__this.rows', this.rows);


    // Filtrar las filas donde usr_asignado es NULL
    const noAssignedRows = this.rows.filter((r: { usr_asignado: null; }) => r.usr_asignado === null);

    if (this.selectNoAssigned) {
      // Si está seleccionando, agrega las filas no asignadas a las seleccionadas
      this.selected = [...this.selected, ...noAssignedRows];
    } else {
      // Si no está seleccionando, filtra las filas no asignadas de las seleccionadas
      this.selected = this.selected.filter((r: any) => !noAssignedRows.includes(r));
    }

    this.filtraEmpezados();
    this.totalesSelected();
  }


  filtraEmpezados() {
    // No seleccionar los ya empezados
    this.selected = this.selected.filter(
      (item: { estado: number }) => item.estado == 1
    );
  }

  totalesSelected() {
    // Calcula los totales sobre los seleccionados
    this.totSelCantidad = this.selected.reduce((total: number, obj: { cant_reserva: string; }) => total + parseInt(obj.cant_reserva), 0);
    this.totSelUbicaciones = this.selected.reduce((total: number, obj: { totUbicaciones: string; }) => total + parseInt(obj.totUbicaciones), 0);
  }

  limitarYAgregarPuntos(value: string, maxLength: number): string {
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + '...';
    } else {
      return value;
    }
  }


}
