import { Pipe, PipeTransform } from '@angular/core';
// Recogidas
// Tabla: preparaciones-> campo: estado

@Pipe({
  name: 'preparacionEstado'
})
export class PreparacionEstadoPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    // PREPARACIÓN 1 pendiente, 2 empezada, 3- Completo, 8 error

    switch (value) {
      case '1':
        return '<span class="text-info">Pendiente</span>';
      case '2':
        return '<span class="text-warning">Empezada</span>';
      case '3':
        return '<span class="text-success">Finalizada</span>';
      case '8':
        return '<span class="text-danger">Incidencia</span>';
      default:
        return '<span class="text-muted">Desconocido</span>';
    }

  }

}
