<div class="card">
    <div class="card-body">
        <h4 class="card-title">Detalle del pedido</h4>
        <div class="text-info">
            <span class="font-weight-bold"> {{pedidoDetall.pedido_cli}} </span>
            <span [innerHTML]=" pedidoDetall.estado | pedidoEstado "> </span>
            <span class="font-italic text-muted text-sm"> {{ pedidoDetall.estado_at }}</span>
        </div>


        <h6 class="card-subtitle">{{pedidoDetall.nombre}}
            <span *ngIf="pedidoDetall.contrarrembolso && pedidoDetall.contrarrembolso > 0"
                  class="text-danger font-italic text-sm">
                (contrarrembolso de {{ pedidoDetall.contrarrembolso | number: '0.0-0':'es'}} €)
            </span>
        </h6>

        <h6 class="card-subtitle"> {{pedidoDetall.cp}} - {{pedidoDetall.poblacion}}</h6>

        <div class="bootstrap-table">

            <div>
                <table class="table table-sm table-striped table-bordered table-hover">

                    <thead>
                        <tr>
                            <th></th>
                            <th>SKU</th>
                            <th>Ean</th>
                            <th>Descripción</th>
                            <th style="text-align: center; padding-left: 10px; white-space: nowrap; width: 1px;">Pedido</th>
                            <th style="text-align: center; padding-left: 10px; white-space: nowrap; width: 1px;">A Repartir</th>
                            <th style="text-align: center; padding-left: 10px; white-space: nowrap; width: 1px;">Repartido</th>
                        </tr>
                    </thead>

                    <!-- Si hay detall y no está ubicado, mostrarlo -->
                    <tbody>
                        <ng-container *ngFor="let detall of pedidoDetall.detall; let i = index">

                            <tr>
                                <td>
                                    <img [src]="fotoDir +'/articulos' + imgResized + detall.artFoto"
                                         loading="lazy"
                                         alt="Foto"
                                         class="rounded pointer border"
                                         height="25px">
                                </td>
                                <td>{{ detall.artSku }}</td>
                                <td>{{ detall.artEan }}</td>
                                <td>{{ detall.artDescripcion }}</td>

                                <td style="text-align: right; padding-left: 10px">
                                    <div class="pl-25 mr-20 monospace">
                                        {{ detall.cantidad | number: '0.0-0':'es'}}
                                    </div>
                                </td>
                                <td style="text-align: right; padding-left: 10px">
                                    <div class="pl-25 mr-20 monospace"
                                         [ngClass]="{'text-danger': (detall.cantidad != detall.cant_repartida)}">
                                        {{ detall.cant_repartir | number: '0.0-0':'es'}}
                                    </div>
                                </td>
                                <td style="text-align: right; padding-left: 10px">
                                    <div class="pl-25 pr-2 mr-20 monospace"
                                         [ngClass]="{
                                            'bg-green rounded': (detall.cant_repartir == detall.cant_repartida) && detall.cant_repartida! > 0,
                                            'bg-blue rounded': (detall.cant_repartida! < detall.cant_repartir!) && detall.cant_repartida! > 0
                                         }">
                                        {{ detall.cant_repartida | number: '0.0-0':'es'}}
                                    </div>
                                </td>

                            </tr>
                        </ng-container>
                    </tbody>


                    <tfoot>
                        <tr>
                            <td colspan="2"></td>
                            <td style="text-align: right; padding-left: 10px">
                                <div class="pl-25 mr-20 monospace">
                                    {{ pedidoDetall.tot_referencias | number: '0.0-0':'es' }}
                                </div>
                            </td>

                            <td></td>
                            <td style="text-align: right; padding-left: 10px">
                                <div class="pl-25 mr-20 monospace">
                                    {{ pedidoDetall.tot_articulos | number: '0.0-0':'es' }}
                                </div>
                            </td>
                            <td style="text-align: right; padding-left: 10px">
                                <div class="pl-25 mr-20 monospace">
                                    {{ pedidoDetall.tot_cant_repartir | number: '0.0-0':'es' }}
                                </div>
                            </td>
                            <td style="text-align: right; padding-left: 10px">
                                <div class="pl-25 mr-20 monospace pr-2">
                                    {{ pedidoDetall.tot_cant_repartida | number: '0.0-0':'es' }}
                                </div>
                            </td>
                        </tr>
                    </tfoot>


                </table>
            </div>



        </div>
    </div>

    <div class="clearfix"></div>
</div>