import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UsuarioService } from './usuario.service';
import { IApiResp } from 'src/app/interfaces/api-resp';
import { Observable, catchError, delay, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RepartosService {


  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService
  ) { }


  getUicInfo(uic: string) {
    // Devulve info de los repartos de un UIC
    const path = `${this.apiURL}/repartos/uicInfo/${uic}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }

  getRepartosListUic(uic: string) {
    // Devulve repartos de un UIC
    const path = `${this.apiURL}/repartos/repartoListUic/${uic}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }

  getRepartosListUicArt(uic: string, id_aric: string) {
    // Devulve repartos de un artículo dentro de un UIC, añadiendo también los ya repartidos de otros uic's DE LA MISMA CAMPAÑA
    const path = `${this.apiURL}/repartos/repartoListUic/${uic}/${id_aric}/${1}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }


  addCaja(data: any) {
    console.log('servicio addCaja data:', data)
    // Añade la caja caja_num al cajetín del pedido id_pedido
    const path = `${this.apiURL}/repartos/addCaja`;
    return this.http.post<IApiResp>(path, data);
  }

  addCantToCaja(data: any) {
    // Añade artículo en una caja 
    const path = `${this.apiURL}/repartos/addCantToCaja`;
    return this.http.post<IApiResp>(path, data);
  }


  pdfEtiqueta(data: any) {
    // Imprime la etiqueta de una caja
    const path = `${this.apiURL}/repartos/pdfEtiqueta`;
    return this.http.post<IApiResp>(path, data);
  }

  pdfEtiquetaMulti(data: any) {
    // Imprime todas las etiquetas de un array
    const path = `${this.apiURL}/repartos/pdfEtiquetaMulti`;
    return this.http.post<IApiResp>(path, data);
  }

  pedidoSaleUnoaUno(data: any) {
    // Genera el envío de un pedido (campaña de un pedido)
    const path = `${this.apiURL}/repartos/pedidoSaleUnoaUno`;
    return this.http.post<IApiResp>(path, data);
  }


  pedidoNoSale(data: any) {
    // Acción especiál sobre un pedido, el pedido no saldrá NUNCA
    const path = `${this.apiURL}/repartos/pedidoNoSale`;
    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }

  pedidoSaleParcial(data: any) {
    // Acción especiál sobre un pedido, el pedido sale como está 
    const path = `${this.apiURL}/repartos/pedidoSaleParcial`;
    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }

  getPedidoArticulosPendientes(id: number) {
    // Devuelve los artículos que está en un Pedido y NO está en el cajetín
    const path = `${this.apiURL}/repartos/getPedidoArticulosPendientes/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }


}
