<div *ngIf="loading"
     class="loading-overlay">
    <div class="spinner"></div>
    <h3 class="mt-3 ml-1 text-info ">Cargando...</h3>
</div>

<div class="row ">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body animated fadeIn fast">


                <!-- cabecera -->
                <div class="row mb-2">

                    <div class="col-6">
                        <div class="form-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   (keyup)="buscarTodo($event)" />
                        </div>
                    </div>

                    <div class="col-3">
                        <div class=" input-group border border-dark rounded">
                            <div class="input-group-addon"><i class="fa-solid fa-warehouse"></i></div>
                            <select class="form-control "
                                    [(ngModel)]="almSeleccionado"
                                    (ngModelChange)="onAlmacenSelect($event)">
                                <option value=0>Todos los almacenes</option>
                                <option *ngFor="let alm of almacenesList"
                                        [value]=alm.id>
                                    {{alm.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>


                    <div class="col-3">
                        <form>

                            <div class="row float-right">
                                <div class="col-sm-6">
                                    <div class="form-group rounded">
                                        <select class="form-control"
                                                name="rowsLimitStr"
                                                [(ngModel)]="rowsLimitStr"
                                                (change)="rowsLimitChange()">
                                            <option [value]="10">10</option>
                                            <option [value]="50">50</option>
                                            <option [value]="100">100</option>
                                            <option [value]="999999">Todo</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group float-right">
                                        <div class="btn-group">
                                            <button type="button"
                                                    [routerLink]="'/panel/ubicacion/new'"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Nuevo">
                                                <i class="fa-solid fa-plus"></i>
                                            </button>
                                            <button type="button"
                                                    [routerLink]="'/panel/ubicaciones_labels'"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Imprimir Eqtiquetas">
                                                <i class="fa-solid fa-tag"></i>
                                            </button>
                                            <button type="button"
                                                    (click)="exportToExcel()"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Descargar Excel">
                                                <i class="fa-solid fa-download"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </form>
                    </div>

                </div>



                <div class="rounded border">
                    <!-- datatable -->
                    <ngx-datatable class="bootstrap"
                                   [rows]="rows"
                                   [limit]="rowsLimit"
                                   [loadingIndicator]="loading"
                                   [columnMode]="ColumnMode.force"
                                   [headerHeight]="50"
                                   [footerHeight]="50"
                                   [summaryRow]="false"
                                   [summaryHeight]="55"
                                   [summaryPosition]="'bottom'"
                                   [rowHeight]="'auto'"
                                   [messages]="messages"
                                   [reorderable]="true">



                        <!-- codigo -->
                        <ngx-datatable-column name="Código"
                                              prop='codigo'
                                              [width]="160"
                                              [maxWidth]="160"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div style="font-family: 'Verdana', monospace; letter-spacing: 0.2px;"
                                     [innerHTML]="resaltarTexto(value | mask: '00.00.00.00.00.00', textoDestacado | mask: '00.00.00.00.00.00')"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- swOcupado -->
                        <ngx-datatable-column name="Ocupado"
                                              prop='swOcupado'
                                              [width]="100"
                                              [maxWidth]="100">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div title="Ocupada"
                                     [innerHTML]="value | iconCheck"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- descripcion -->
                        <ngx-datatable-column name="Descripción del código"
                                              prop='descripcion'
                                              [width]="300"
                                              [maxWidth]="500">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div class="italic small"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- peso -->
                        <ngx-datatable-column name="Peso"
                                              prop='peso'
                                              [width]="100"
                                              [maxWidth]="100"
                                              [cellClass]="'text-right'">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div class="italic small"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- orden_recogida -->
                        <ngx-datatable-column name="Orden"
                                              prop='orden_recogida'
                                              [width]="100"
                                              [maxWidth]="100"
                                              [cellClass]="'text-right'">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div class="italic small"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- comentario -->
                        <ngx-datatable-column name="
                                     Comentario"
                                              prop='comentario'
                                              [width]="300"
                                              [maxWidth]="500">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- tipo.nombre -->
                        <ngx-datatable-column name="Tipo"
                                              [width]="250"
                                              [maxWidth]="250">
                            <ng-template let-row="row"
                                         ngx-datatable-cell-template>
                                <div *ngIf="row.tipo"
                                     [innerHTML]="resaltarTexto(row.tipo.nombre, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- tipo.dimensiones -->
                        <ngx-datatable-column name="Dim.máx. cm"
                                              title="Largo x Ancho x Alto"
                                              [width]="150"
                                              [maxWidth]="150">
                            <ng-template let-row="row"
                                         title="Largo x Ancho x Alto"
                                         ngx-datatable-cell-template>
                                <div *ngIf="row.tipo"
                                     [innerHTML]="resaltarTexto(row.tipo.largo +'x'+row.tipo.ancho+'x'+ row.tipo.alto, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- añadir / editar / borrar -->
                        <ngx-datatable-column [width]="50"
                                              [maxWidth]="100"
                                              [sortable]="false"
                                              [resizeable]="false">


                            <ng-template let-row="row"
                                         let-value="value"
                                         ngx-datatable-cell-template>
                                <!-- 
                                <a [routerLink]="['/panel/ubicacion',row.id]"
                                   class="link mr-2"
                                   title="Editar">
                                    <i class="fa-regular fa-pen-to-square fa"></i>
                                </a>

                                <a (click)="deleteRow(row)"
                                   class="link mr-2 pointer"
                                   title="Borrar">
                                    <i class="fa-regular fa-trash-can fa"></i>
                                </a> 
-->


                                <a [routerLink]="['/panel/ubicacion',row.id]"
                                   class="link mr-2 pointer"
                                   title="Editar">
                                    <span class="badge">E</span>
                                </a>
                            </ng-template>


                        </ngx-datatable-column>

                    </ngx-datatable>
                </div>

            </div>
        </div>
    </div>

</div>