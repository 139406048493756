export const environment = {
  production: false,
  entorno: 'ENTORNO TEST',
  timeOut: 600000,   // Milisegundos de inactividad que provocan la DESCONEXIÓN automática (10min = 600000 ms)
  timeRefreshCampanyas: 5000,  // Refesco automático de los datos de campañas. 5000 milisegundos = 5 segundos
  timeRefreshPda: 5000,  // Refesco automático de los datos de listados en PDA. 5000 milisegundos = 5 segundos

  apiErrorAuth: 'El sistema ha devuelto un error. <br><i>Asegurate de estar autorizado para hacer esta operación</i>',
  defaultTema: 'default-dark.css',


  // URL absoluta
  url: 'https://clientes.test.innovacen.com',

  // URL de la API
  apiURL: 'https://api.test.innovacen.com/api',

  // Directorios
  fotoDir: 'https://api.test.innovacen.com/data/fotos',
  pdfDir: 'https://api.test.innovacen.com/data/pdfs',


  // roles
  rolesAdministracion: ['admin', 'sa']
};
