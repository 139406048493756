
//componentes
import { ModalImagenComponent } from './modal-imagen/modal-imagen.component';

// Módulos
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalRegularizarComponent } from './modal-regularizar/modal-regularizar.component';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';
import { ModalPdfComponent } from './modal-pdf/modal-pdf.component';
import { ModalFactSalidaComponent } from './modal-fact-salida/modal-fact-salida.component';
import { ModalFactEntradaComponent } from './modal-fact-entrada/modal-fact-entrada.component';


@NgModule({
  declarations: [
    ModalImagenComponent,
    ModalPdfComponent,
    ModalRegularizarComponent,
    ModalFactSalidaComponent,
    ModalFactEntradaComponent,
  ],
  exports: [
    ModalImagenComponent,
    ModalPdfComponent,
    ModalRegularizarComponent,
    ModalFactSalidaComponent,
    ModalFactEntradaComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxMaskModule.forRoot(),
  ]
})
export class ComponentesModule { }
