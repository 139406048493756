<div class="row animated fadeIn fast">

    <div class="col-lg-12">
        <div class="card">

            <div class="card-body">
                <form class="form p-t-20"
                      [formGroup]="labelForm"
                      (ngSubmit)="enviar()"
                      class="form-horizontal">
                    <div class="form-body">

                        <div class="row">



                            <!-- id_almacen -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['id_almacen'].errors }">
                                    <label class="form-control-label">Almacen</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"> <i class="fa-solid fa-warehouse"></i></div>

                                        <select formControlName="id_almacen"
                                                class="form-control"
                                                [ngClass]="{ 'form-control-danger': enviado && f['id_almacen'].errors }">
                                            <option value="">Selecciona...</option>
                                            <option *ngFor="let alm of almacenesList"
                                                    [value]="alm.id">
                                                {{alm.nombre}}
                                            </option>
                                        </select>
                                    </div>
                                    <div *ngIf="enviado && f['id_almacen'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="labelForm.get('id_almacen')?.errors?.['apiError']">{{
                                            labelForm.get('id_almacen')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>



                            <!-- almacen_desde -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['almacen_desde'].errors }">
                                    <label class="form-control-label">Imprimir etiqueta desde ubicación:</label>
                                    <input type="text"
                                           mask="00.00.00.00.00.00"
                                           (input)="cambiaUbiDesde()"
                                           formControlName="almacen_desde"
                                           class="form-control grande"
                                           [ngClass]="{ 'form-control-danger': enviado && f['almacen_desde'].errors }">
                                    <div *ngIf="enviado && f['almacen_desde'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['almacen_desde'].errors['minlength']">Deben ser 12 digitos</div>
                                        <div *ngIf="f['almacen_desde'].errors['maxlength']">Deben ser 12 digitos</div>
                                        <div *ngIf="f['almacen_desde'].errors?.['apiError']">{{labelForm.get('almacen_desde')?.errors?.['apiError']}}</div>
                                    </div>
                                    <small>{{infoUbiDesde}}</small>
                                </div>
                            </div>

                            <!-- almacen_hasta -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['almacen_hasta'].errors }">
                                    <label class="form-control-label">Imprimir etiqueta hasta ubicación:</label>
                                    <input type="text"
                                           (input)="cambiaUbiHasta()"
                                           mask="00.00.00.00.00.00"
                                           formControlName="almacen_hasta"
                                           class="form-control grande"
                                           [ngClass]="{ 'form-control-danger': enviado && f['almacen_hasta'].errors }">
                                    <div *ngIf="enviado && f['almacen_hasta'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['almacen_hasta'].errors['minlength']">Deben ser 12 digitos</div>
                                        <div *ngIf="f['almacen_hasta'].errors['maxlength']">Deben ser 12 digitos</div>
                                        <div *ngIf="f['almacen_hasta'].errors?.['apiError']">{{labelForm.get('almacen_hasta')?.errors?.['apiError']}}</div>
                                    </div>
                                    <small>{{infoUbiHasta}}</small>
                                </div>
                            </div>



                        </div> <!-- row -->


                        <hr>

                        <div class="d-flex">
                            <button [disabled]="loading"
                                    type="button"
                                    class="btn waves-effect waves-light btn-rounded btn-secondary"
                                    [routerLink]="'/panel/ubicaciones'"
                                    data-dismiss="modal">Cancelar</button>


                            <button [disabled]="loading"
                                    type="submit"
                                    class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Enviar</button>
                        </div>




                    </div>
                </form>
            </div>
        </div>
    </div>



    <div class="col-lg-12"
         *ngIf="loading">
        <div class="card">
            <div class="card-body">
                <h2 class="card-title">
                    <i *ngIf="loading"
                       class="fa fa-spinner fa-spin"></i>
                    Generando etiquetas de ubicaciones...
                </h2>
            </div>
        </div>
    </div>


    <div class="col-lg-12"
         *ngIf="ubicacionesList.length > 0">
        <div class="card">
            <div class="card-body">
                <h2 class="card-title">{{ubicacionesList.length}} Etiquetas impresas</h2>
                <p>
                    <span *ngFor="let ubicacion of ubicacionesList; let last = last"
                          class="monospace">
                        {{ubicacion.codigo | mask: '00.00.00.00.00.00'}}{{last ? '' : ' -'}}
                    </span>
                </p>
            </div>
        </div>
    </div>

</div>